import { useCallback, useState } from 'react'
import { QuestionUploadAudioFiles } from '../models/QuestionUploadAudioFile'
import { Question } from 'types'

export function useQuestionAudios(question: Question) {
  const {
    enQuestionMediaFileId,
    enExplanationMediaFileId,
    esQuestionMediaFileId,
    esExplanationMediaFileId,
  } = question

  const [uploadFiles, setUploadFiles] = useState<QuestionUploadAudioFiles>({
    enQuestionMediaFile: enQuestionMediaFileId ? { id: enQuestionMediaFileId } : null,
    enExplanationMediaFile: enExplanationMediaFileId ? { id: enExplanationMediaFileId } : null,
    esQuestionMediaFile: esQuestionMediaFileId ? { id: esQuestionMediaFileId } : null,
    esExplanationMediaFile: esExplanationMediaFileId ? { id: esExplanationMediaFileId } : null,
  })

  const updateFileId = useCallback(
    (uploadFileName: keyof QuestionUploadAudioFiles, newFileId: number | null) => {
      setUploadFiles((files) => {
        const fileItem = files[uploadFileName]
        return {
          ...files,
          [uploadFileName]: {
            ...fileItem,
            id: newFileId,
          },
        }
      })
    },
    [],
  )

  return { uploadFiles, setUploadFiles, updateFileId }
}
