import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import React, { useCallback, useState } from 'react'
import { UIAudioPlayer } from 'features/UI'
import { AudioLanguages } from '../../models/Article.model'
import * as Styled from './ArticleAudioUploadItem.styled'

export function ArticleAudioUploadItem({
  src,
  language,
  mimeType,
  additionAttr,
  onRemove,
}: {
  src: string
  language: AudioLanguages
  onRemove: (language: AudioLanguages) => void
  mimeType?: string
  additionAttr?: string | JSX.Element
}) {
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  const handleOpenRemoveModal = () => {
    setIsRemoveModalOpen(true)
  }

  const handleCloseRemoveModal = () => {
    setIsRemoveModalOpen(false)
  }

  const handleRemove = useCallback(() => {
    onRemove(language)
  }, [language, onRemove])

  return (
    <Styled.Container>
      <Styled.LangContainer>{language}</Styled.LangContainer>

      {additionAttr && <Styled.AdditionAttrContainer>{additionAttr}</Styled.AdditionAttrContainer>}

      <Styled.PlayerContainer>
        <UIAudioPlayer src={src} mediaFileMimeType={mimeType} key={src} />
      </Styled.PlayerContainer>

      <IconButton aria-label="delete" onClick={handleOpenRemoveModal}>
        <DeleteIcon />
      </IconButton>

      <ConfirmRemoveModal
        isOpen={isRemoveModalOpen}
        entityToRemove="Audio"
        loading={false}
        handleClose={handleCloseRemoveModal}
        handleConfirm={handleRemove}
      />
    </Styled.Container>
  )
}
