import { styled } from '@mui/material/styles'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: '4px 8px',
}))

export const PlayerContainer = styled('div')`
  flex-grow: 1;
`

export const LangContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: '4px 8px',
  textTransform: 'uppercase',
}))

export const AdditionAttrContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: '4px 8px',
  textAlign: 'center',
}))
