import React from 'react'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { defaultModalStyle, defaultModalZIndex, minButtonWidth } from '../../const'
import { LoadingButton } from '@mui/lab'
import { PublishAction } from '../../hooks/usePublishModalControl'
import { capitalizeFirstLetter } from '../../utils'

interface Props {
  isOpen: boolean
  entity: string
  loading: boolean
  action: PublishAction
  body?: string | null
  handleClose: () => void
  handleConfirm: () => void
}

const modalStyle = {
  ...defaultModalStyle,
  width: 420,
}

const ConfirmPublishModal = (props: Props) => {
  const { isOpen, entity, loading, handleClose, handleConfirm, action, body } = props

  const actionText = action === PublishAction.Publish ? 'publish' : 'unpublish'

  const modalBody = body ? (
    <Typography variant="body1" mb={5}>
      {body}
    </Typography>
  ) : (
    <>
      <Typography variant="body1">
        You are trying to {actionText} {entity}.
      </Typography>
      <Typography variant="body1" mb={5}>
        Please confirm the action.
      </Typography>
    </>
  )

  return (
    <Modal sx={defaultModalZIndex} open={isOpen}>
      <Box sx={modalStyle}>
        <Typography variant="h5" mb={3}>
          {capitalizeFirstLetter(actionText)} {entity}
        </Typography>
        {modalBody}
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
            Close
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleConfirm}
            sx={minButtonWidth}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ConfirmPublishModal
