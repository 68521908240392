import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { Query, TableResponse } from 'types'

export function useQueryMetadata(queryList?: TableResponse<Query>): LabelValue[] {
  return useMemo(() => {
    if (!queryList) {
      return []
    }

    return [
      {
        label: 'Total number',
        value: queryList.data.count,
      },
      {
        label: 'Queries published',
        value: queryList.data.publishedCount || 0,
      },
      {
        label: 'Linked Learn library Article Total',
        value: queryList.data.linkedArticlesCount || 0,
      },
    ]
  }, [queryList])
}
