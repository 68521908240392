import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import GroupIcon from '@mui/icons-material/Group'
import KeyIcon from '@mui/icons-material/Key'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import List from '@mui/material/List'
import { useToggle } from 'hooks/useToggle'
import React from 'react'
import { routes } from 'routes/routes'
import { CareArticleType, DrawerListItem, EngagementReportTypes, EQuestionType } from 'types'
import { getRoute } from 'utils'
import DrawerHeaderFiller from './common/DrawerHeaderFiller'
import NavItem from './common/NavItem'
import { DrawerCollapseHeader } from './DrawerCollapseHeader'
import { DrawerCollapseList } from './DrawerCollapseList'

const contentReports: DrawerListItem[] = [
  {
    text: 'View Count of Lessons',
    path: routes.reports_lessons,
  },
  {
    text: 'Active Engagement',
    path: routes.reports_engagement,
  },
  {
    text: 'Quizzes',
    path: routes.reports_quizzes,
  },
  {
    text: 'Exams',
    path: routes.reports_questionnaires,
  },
]

const userReports: DrawerListItem[] = [
  {
    text: 'Engagement Per User',
    path: getRoute(routes.reports_engagement_user, {
      engagementType: EngagementReportTypes.Course,
    }),
  },
]

const contentManagement: DrawerListItem[] = [
  {
    text: 'Manage Courses and Lessons',
    path: routes.courses,
  },
  {
    text: 'Manage Learn Library',
    path: routes.manageLearnLibrary,
  },
  {
    text: 'Manage Pathways',
    path: routes.managePathways,
  },
  {
    text: 'Manage Timeline Posts',
    path: getRoute(routes.manageCarePosts, { type: CareArticleType.Care }),
  },
  {
    text: 'Manage Query',
    path: routes.manageQuery,
  },
  {
    text: 'Manage Questions',
    path: getRoute(routes.questions, { type: EQuestionType.question }),
  },
]

const tagsManagement: DrawerListItem[] = [
  {
    text: 'Manage Tags For Content',
    path: routes.manageTags,
  },
  {
    text: 'Manage Tags For Users',
    path: routes.manageCohorts,
  },
]

const AdminDrawerContent = () => {
  const [isShowReports, toggleReports] = useToggle(true)
  const [isShowUserReports, toggleUserReports] = useToggle(true)
  const [isShowManagement, toggleManagement] = useToggle(true)
  const [isShowTags, toggleTags] = useToggle(true)

  return (
    <>
      <DrawerHeaderFiller />
      <List disablePadding>
        <NavItem icon={<GroupIcon />} path={routes.users} isSmall>
          Users
        </NavItem>
      </List>

      <List disablePadding>
        <NavItem icon={<KeyIcon />} path={routes.accessManagement} isSmall>
          Access Management
        </NavItem>
      </List>

      <DrawerCollapseHeader
        title="Content Reports"
        icon={<ListAltIcon />}
        isOpen={isShowReports}
        onToggle={toggleReports}
      />
      {isShowReports && <DrawerCollapseList list={contentReports} />}

      <DrawerCollapseHeader
        title="User Reports"
        icon={<SummarizeOutlinedIcon />}
        isOpen={isShowUserReports}
        onToggle={toggleUserReports}
      />
      {isShowUserReports && <DrawerCollapseList list={userReports} />}

      <DrawerCollapseHeader
        title="Content Management"
        icon={<CoPresentIcon />}
        isOpen={isShowManagement}
        onToggle={toggleManagement}
      />
      {isShowManagement && <DrawerCollapseList list={contentManagement} />}

      <DrawerCollapseHeader
        title="Tags"
        icon={<BookmarkBorderOutlinedIcon />}
        isOpen={isShowTags}
        onToggle={toggleTags}
      />
      {isShowTags && <DrawerCollapseList list={tagsManagement} />}
    </>
  )
}

export default AdminDrawerContent
